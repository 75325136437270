import React, { LegacyRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth0 from '../../hooks/auth0';
import { useMove } from '../../hooks/move';
import Loader from '../../components/loader';
import GlobalContext from '../../store/GlobalContext';
import { useTitle } from '../../hooks/title';
import Arrow from '../../components/arrow';
import Card from '../../components/card';
import PasswordToggle from '../../components/password-toggle';
import PageTitle from '../../components/page-title';

const Login: React.FC = () => {
  useTitle('Login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordInputType, setPasswordInputType] = useState('password');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [mustResetPassword, setMustResetPassword] = useState(false);
  const [success, setSuccess] = useState('');
  const { auth0Client } = useAuth0();
  const { utilityService, apiService } = useContext(GlobalContext);
  const move = useMove();
  const emailRef: any = useRef();
  const formRef: any = useRef();
  const handleSubmit = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setError('');
    if (!email || !password) {
      setError('*Email and password are both required.');
      return;
    }
    if (!utilityService.validateEmail(email)) {
      setError(
        "Email format is invalid. Please include an '@' and an extension in the email address.",
      );
      return;
    }
    setLoading(true);
    try {
      auth0Client.login(
        {
          username: email,
          password,
          realm: 'Username-Password-Authentication',
        },
        async (response) => {
          console.log({ response });
          if (response?.description && response?.description.includes('Wrong email')) {
            try {
              const response = await apiService.createAuth0UserAndSendPasswordREset(email);
              console.log({ response });
              if (response.success) {
                setMustResetPassword(true);
                setError(
                  'Time to update your password. An email has been sent to your email address. Please click on the link in the email to reset your password.',
                );
              } else {
                setError('Invalid email or password.');
              }
            } catch (e) {
              setError('Invalid email or password.');
            }
          }
          setLoading(false);
        },
      );
    } catch (e) {
      console.error;
      const apiError: any = e;
      console.log({ apiError });

      if (apiError?.description) {
        try {
          const response = await apiService.createAuth0UserAndSendPasswordREset(email);
          console.log({ response });
          if (response.success) {
            setMustResetPassword(true);
            setError(
              'Time to update your password. An email has been sent to your email address. Please click on the link in the email to reset your password.',
            );
          }
        } catch (e) {
          setError(apiError?.description);
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    auth0Client.checkSession({}, (err, authResult) => {
      if (authResult) {
        move('/');
        return;
      }
    });

    if (getEmailInQuery) {
      setEmail(getEmailInQuery);
    }
  }, []);

  const togglePasswordInputType = () => {
    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
  };

  const getEmailInQuery = useMemo(() => {
    const search = new URLSearchParams(window.location.search).get('email');
    return search;
  }, []);

  return (
    <>
      <PageTitle>Log into your account</PageTitle>
      <Card>
        <form ref={formRef} className="form">
          <label className="form__label" htmlFor="email">
            Email*
          </label>
          <input
            id="email"
            ref={emailRef}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoFocus={getEmailInQuery ? false : true}
            name="email"
            className="form__input form__input--text"
            type="text"
            value={email}
          />
          <label className="form__label" htmlFor="password">
            Password*
          </label>
          <div className="password-input">
            <input
              id="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              autoFocus={getEmailInQuery ? true : false}
              name="password"
              className="form__input form__input--text"
              type={passwordInputType}
            />
            <span
              className="password-input__toggle"
              role="button"
              onClick={togglePasswordInputType}
            >
              <PasswordToggle passwordInputType={passwordInputType} />
            </span>
          </div>
          {error ? <div className="form__error">{error}</div> : null}
          {success ? <div className="form__success">{success}</div> : null}
          {loading ? (
            <Loader />
          ) : (
            !mustResetPassword && (
              <button
                className="form__input form__input--submit"
                type="submit"
                onClick={handleSubmit}
              >
                Login
                <Arrow />
              </button>
            )
          )}

          {!mustResetPassword && (
            <div className="form__link">
              <Link to="/reset-password">Reset Password</Link>
            </div>
          )}
        </form>
      </Card>
      <div className="login__question">
        <span>Don’t have an account?</span>
        <span>
          <a
            className="button button--outline button--link button--login"
            href="https://sellit.com/account/"
          >
            Sign Up
            <Arrow />
          </a>
        </span>
      </div>
    </>
  );
};

export default Login;
