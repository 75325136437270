import React from 'react';
import { CircularProgress } from '@material-ui/core';
const Loader: React.FC = () => {
  return (
    <div className="loader">
      <CircularProgress size={40} />
    </div>
  );
};

export default Loader;
