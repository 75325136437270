import { useEffect } from 'react';
import { useLocation } from 'react-router';

/** Hook for changing title */
export const useTitle = (title: string) => {
  const location = useLocation();
  useEffect(() => {
    title && (document.title = `${title} - Sell it Like Serhant`);
  }, [title, location]);
};
