import axios from 'axios';

const localApiBaseUrl = 'http://127.0.0.1:8080';

export default class ApiService {
  public processPath = (path: string): string =>
    window.location.host.includes('127.0.0.1') || window.location.host.includes('localhost')
      ? localApiBaseUrl.concat(path)
      : path;

  public emailActivationLink = async (email: string, checkContact = true, redirectUri?: string) => {
    const path = '/api/gateway/email-activation-link';
    const rawResponse = await axios.post(this.processPath(path), {
      email,
      checkContact,
      redirectUri,
    });
    return rawResponse.data;
  };

  public emailPasswordResetLink = async (email: string) => {
    const path = '/api/gateway/password-reset-email';
    const rawResponse = await axios.post(this.processPath(path), {
      email,
    });
    return rawResponse.data;
  };

  public validatePasswordResetPayload = async (email: string) => {
    const path = '/api/gateway/password-reset-validation';
    const rawResponse = await axios.post(this.processPath(path), {
      email,
    });
    return rawResponse.data;
  };

  public createAuth0UserAndSendPasswordREset = async (email: string) => {
    const path = '/api/gateway/create-auth0-user-password-reset';
    const rawResponse = await axios.post(this.processPath(path), {
      email,
    });
    return rawResponse.data;
  };

  public submitPasswordReset = async (payload: any) => {
    const path = '/api/gateway/password-reset-submit';
    const rawResponse = await axios.post(this.processPath(path), payload);
    return rawResponse.data;
  };

  public submitActivationPayload = async (payload: any) => {
    const path = '/api/gateway/activation';
    const rawResponse = await axios.post(this.processPath(path), payload);
    return rawResponse.data;
  };

  public submitSignUpPayload = async (payload: any) => {
    const path = '/api/gateway/sign-up';
    const rawResponse = await axios.post(this.processPath(path), payload);
    return rawResponse.data;
  };

  public loginWithAccessToken = async (token: string) => {
    const path = '/api/gateway/login';
    const rawResponse = await axios.post(this.processPath(path), { token });
    return rawResponse.data;
  };

  public getAuth0Config = async () => {
    const path = '/api/gateway/auth0-config';
    const rawResponse = await axios.get(this.processPath(path));
    return rawResponse.data;
  };
}
