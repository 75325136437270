import React, { useEffect } from 'react';
import useAuth0 from '../../hooks/auth0';
import Loader from '../../components/loader';
import Card from '../../components/card';

const Logout: React.FC = () => {
  const { auth0Client } = useAuth0();

  useEffect(() => {
    const returnTo = 'https://sellitlikeserhant.com/';
    setTimeout(() => {
      auth0Client.checkSession({}, (err, authResult) => {
        if (authResult) {
          auth0Client.logout({ returnTo });
          return;
        } else {
          window.location.href = returnTo;
        }
      });
    }, 1000);
  }, []);

  return (
    <Card>
      <Loader />
    </Card>
  );
};

export default Logout;
