import React from 'react';
import { ReactComponent as EyeClosedIcon } from '../assets/eye-closed.svg';
import { ReactComponent as EyeOpenedIcon } from '../assets/eye-opened.svg';

interface PasswordToggleInterface {
  passwordInputType?: string;
}

const PasswordToggle: React.FC<PasswordToggleInterface> = ({ passwordInputType }) => {
  return (
    <>
      {passwordInputType === 'password' && <EyeClosedIcon />}
      {passwordInputType === 'text' && <EyeOpenedIcon />}
    </>
  );
};

export default PasswordToggle;
