import React from 'react';

const logoUrl = '/sell-it-logo.svg';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="header__container">
        <a className="header__link" href="https://sellit.com/" aria-label="Sellit">
          <img loading="lazy" src={logoUrl} alt="Sell It Logo" />
        </a>
      </div>
    </header>
  );
};

export default Header;
