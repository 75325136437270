import { useContext, useMemo } from 'react';
import auth0Sdk from 'auth0-js';
import GlobalContext from '../store/GlobalContext';

const useAuth0 = () => {
  const { auth0 } = useContext(GlobalContext);
  const auth0Client = useMemo(() => {
    const webAuth = new auth0Sdk.WebAuth({
      domain: auth0.domain,
      clientID: auth0.clientId,
      redirectUri: auth0.redirectUrl,
      scope: auth0.scope,
      responseType: 'token',
    });
    return webAuth;
  }, []);
  return { auth0Client };
};

export default useAuth0;
