import { useContext } from 'react';
import { useHistory } from 'react-router';
import GlobalContext from '../store/GlobalContext';

export const useMove = () => {
  const history = useHistory();
  const { queryParams } = useContext(GlobalContext);
  return (path: string, newQueryParams?: URLSearchParams) => {
    const string = newQueryParams ? newQueryParams.toString() : queryParams?.toString();
    history.push({ pathname: path, search: string && string.length > 0 ? string : '' });
  };
};
