import React from 'react';

const links = [
  {
    text: 'Terms of Use',
    url: 'https://sellit.com/terms-of-use/',
  },
  {
    text: 'Privacy Policy',
    url: 'https://sellit.com/privacy-policy/',
  },
];

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="main-container footer__container">
        <div className="footer__copyright">©️ {new Date().getFullYear()} Serhant Ventures LLC</div>

        <ul className="footer__navigation">
          {links.map((link, index) => (
            <li className="footer__item" key={link.url}>
              <a className="footer__link" href={link.url} target="_blank" rel="noopener noreferrer">
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};
export default Footer;
