import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../store/GlobalContext';
import Loader from '../../components/loader';
import { useTitle } from '../../hooks/title';
import Card from '../../components/card';
import PageTitle from '../../components/page-title';
import Arrow from '../../components/arrow';
const SignUp: React.FC = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  useTitle('Sign Up');

  const { apiService, utilityService } = useContext(GlobalContext);

  const handleSubmit = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setError('');
    setSuccess('');
    if (!utilityService.validateEmail(email)) {
      setError('*Email is invalid.');
      return;
    }
    setLoading(true);
    try {
      const response = await apiService.emailActivationLink(email, true);
      if (response.success) {
        setSuccess(response.message);
      } else {
        setError(response.message);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <>
      <PageTitle>Sign Up</PageTitle>
      <Card>
        <form className="form">
          <label className="form__label" htmlFor="email">
            Email*
          </label>
          <input
            id="email"
            autoFocus
            name="email"
            className="form__input form__input--text"
            type="text"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {error ? <div className="form__error">{error}</div> : null}
          {success ? <div className="form__success">{success}</div> : null}
          {loading ? (
            <Loader />
          ) : success ? null : (
            <button
              className="form__input form__input--submit"
              type="submit"
              onClick={handleSubmit}
            >
              Sign Up
              <Arrow />
            </button>
          )}
          <div className="form__link">
            <Link to="/login">Log In</Link> <span className="form__link__bar">|</span>{' '}
            <Link to="/reset-password">Reset Password</Link>
          </div>
        </form>
      </Card>
    </>
  );
};

export default SignUp;
