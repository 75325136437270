import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from '../../store/GlobalContext';
import Loader from '../../components/loader';
import { useMove } from '../../hooks/move';
import useAuth0 from '../../hooks/auth0';
import { useTitle } from '../../hooks/title';
import Card from '../../components/card';
import PasswordToggle from '../../components/password-toggle';
import Arrow from '../../components/arrow';
const Activation: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordInputType, setPasswordInputType] = useState('password');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [activationPayload, setActivationPayload] = useState({});
  const { auth0Client } = useAuth0();
  const { apiService, utilityService } = useContext(GlobalContext);
  const move = useMove();

  useTitle('Activation');

  const handleSubmit = async (event: any) => {
    setError('');
    event.stopPropagation();
    event.preventDefault();
    if (!password || password.length < 8) {
      setError('Password must be at least 8 characters.');
      return;
    }
    if (password !== confirmPassword) {
      setError('Entered passwords are not the same.');
      return;
    }
    setLoading(true);

    try {
      const response = await apiService.submitSignUpPayload({ ...activationPayload, password });
      setSuccess('Password set! Logging you in now...');
      console.log({ response });
      await utilityService.sleep(500);
      auth0Client.login(
        {
          username: email,
          password,
          realm: 'Username-Password-Authentication',
        },
        (response) => {
          setLoading(false);
          console.log({ response });
          if (response?.description && response?.description.includes('Wrong email')) {
            setError('The inputted email or password is incorrect. Please try again.');
          }
        },
      );
    } catch (e) {
      setError(
        `Could not sign up. Please try again later. (Error: ${
          (e as any)?.response?.data?.message
        }}`,
      );
      setLoading(false);
    }
    return;
  };
  useEffect(() => {
    const extractPathParts = () => {
      const pathParts = window.location.pathname.split('/').filter(Boolean);
      if (pathParts.length < 4) {
        return null;
      }
      const string = Buffer.from(pathParts[1], 'base64').toString('utf8');
      let extractedEmail;
      let data;
      try {
        data = JSON.parse(string);
        extractedEmail = data.email;
      } catch (e) {
        extractedEmail = string;
        data = {};
      }
      return {
        email: extractedEmail,
        timestamp: parseInt(pathParts[2]),
        hash: pathParts[3],
        redirectUrl: pathParts[4] ? Buffer.from(pathParts[4], 'base64').toString('ascii') : null,
        firstName: data.firstName,
        lastName: data.lastName,
      };
    };
    const submitActivationPayload = async (parts: any) => {
      if (!parts) {
        return;
      }
      try {
        const response = await apiService.submitActivationPayload(parts);
        console.log({ response });
        if (response.success) {
          setSuccess('Please set your password, to complete your account activation.');
        }
        setLoading(false);
      } catch (e) {
        const message = (e as any)?.response?.data?.message;
        if (message && typeof message === 'string' && message.includes('Please log in')) {
          setError(
            'An account with that email address already exists. Please log in or reset your password.',
          );
          setTimeout(() => move('/login'), 2000);
        } else {
          setError('Activation link is invalid or expired. Please create a new link.');
          setTimeout(() => move('/sign-up'), 2000);
        }
      }
    };

    const parts = extractPathParts();
    if (parts) {
      setActivationPayload(parts);
      submitActivationPayload(parts);
      setEmail(parts.email);
    } else {
      setError('Your activation link is invalid. Please create a new one.');
      return;
    }
    console.log({ parts });
  }, []);
  const togglePasswordInputType = () => {
    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
  };
  return (
    <Card>
      <form className="form">
        {success && (
          <>
            <div className="form__success">{success}</div>
            <br />
            <br />
            <label className="form__label" htmlFor="email">
              Email
            </label>
            <input
              autoFocus
              name="email"
              className="form__input form__input--text"
              type="text"
              value={email}
              disabled
            />
            <label className="form__label" htmlFor="password">
              Password*
            </label>
            <div className="password-input">
              <input
                id="password"
                autoFocus
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                name="password"
                className="form__input form__input--text"
                type={passwordInputType}
              />
              <span
                className="password-input__toggle"
                role="button"
                onClick={togglePasswordInputType}
              >
                <PasswordToggle passwordInputType={passwordInputType} />
              </span>
            </div>
            <label className="form__label" htmlFor="confirmPassword">
              Confirm Password*
            </label>
            <div className="password-input">
              <input
                id="confirmPassword"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                name="confirmPassword"
                className="form__input form__input--text"
                type={passwordInputType}
              />
              <span
                className="password-input__toggle"
                role="button"
                onClick={togglePasswordInputType}
              >
                <PasswordToggle passwordInputType={passwordInputType} />
              </span>
            </div>
          </>
        )}
        {error ? <div className="form__error">{error}</div> : null}{' '}
        {loading ? (
          <Loader />
        ) : (
          <button className="form__input form__input--submit" type="submit" onClick={handleSubmit}>
            Submit
            <Arrow />
          </button>
        )}
      </form>
    </Card>
  );
};

export default Activation;
