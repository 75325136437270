import React, { useContext, useEffect } from 'react';
import './app.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './pages/v2/login';
import Switchboard from './pages/v2/switchboard';
import SignUp from './pages/v2/sign-up';
import ResetPassword from './pages/v2/reset-password';
import Activation from './pages/v2/activation';
import ResetPasswordLink from './pages/v2/reset-password-link';
import Logout from './pages/v2/logout';
import GlobalContext from './store/GlobalContext';
import Footer from './components/footer';
import Header from './components/header';
import { REDIRECT_DESTINATIONS } from './constants/core.constants';
import { Destination } from './types/core.types';

const App: React.FC = () => {
  const { utilityService } = useContext(GlobalContext);

  useEffect(() => {
    const redirectParam = new URLSearchParams(window.location.search).get('redirect');
    if (redirectParam && REDIRECT_DESTINATIONS.includes(redirectParam as Destination)) {
      localStorage.setItem('redirect', redirectParam);
    }
  }, []);

  return (
    <div className={`landing${!utilityService.isMobile() ? ' landing--desktop123' : ''}`}>
      <aside className="aside">
        <Header />
        <div className="landing__inner">
          <Router basename="/">
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/logout">
                <Logout />
              </Route>
              <Route path="/sign-up">
                <SignUp />
              </Route>
              <Route path="/reset-password">
                <ResetPassword />
              </Route>
              <Route path="/reset-password-link">
                <ResetPasswordLink />
              </Route>
              <Route path="/activation-link">
                <Activation />
              </Route>
              <Route path="/">
                <Switchboard />
              </Route>
              <Route path="/*">
                <Switchboard />
              </Route>
            </Switch>
          </Router>

          <div className="landing__contact">
            Need help? Contact <a href="mailto:hello@sellit.com">hello@sellit.com</a>.
          </div>
        </div>
        <Footer />
      </aside>
    </div>
  );
};

export default App;
