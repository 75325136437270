import React, { useContext, useEffect, useState } from 'react';
import { useMove } from '../../hooks/move';
import Loader from '../../components/loader';
import useAuth0 from '../../hooks/auth0';
import GlobalContext from '../../store/GlobalContext';
import { REDIRECT_DESTINATIONS } from '../../constants/core.constants';
import { Destination } from '../../types/core.types';
import { useTitle } from '../../hooks/title';
import Card from '../../components/card';

const Switchboard: React.FC = () => {
  const { auth0Client } = useAuth0();
  const { apiService, queryParams } = useContext(GlobalContext);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const move = useMove();

  useTitle('Login');

  const shouldRedirectTo = (type: Destination) => {
    const redirect = localStorage.getItem('redirect');
    return redirect && REDIRECT_DESTINATIONS.includes(type) && redirect === type;
  };

  const redirectTo = (url: string) => {
    localStorage.removeItem('redirect');
    window.location.href = url;
  };

  useEffect(() => {
    setMessage('');
    const getSsoUrls = async (accessToken: string) => {
      const response = await apiService.loginWithAccessToken(accessToken);
      const { thinkificSsoUrl, profiUrl, sellitSsoUrl } = response?.data;
      if (sellitSsoUrl && shouldRedirectTo('sellit') && queryParams?.get('sellit') != '1') {
        return redirectTo(sellitSsoUrl);
      } else if (thinkificSsoUrl && shouldRedirectTo('thinkific')) {
        return redirectTo(thinkificSsoUrl);
      } else if (profiUrl && shouldRedirectTo('profi')) {
        return redirectTo(profiUrl);
      }
      const fallbackUrl = thinkificSsoUrl ?? profiUrl ?? null;
      if (fallbackUrl) {
        return redirectTo(fallbackUrl);
      } else {
        // setMessage(
        //   'There are no services to redirect to you to. Please contact a Serhant Ventures administrator.',
        // );
        setMessage(
          'It seems like we couldn\'t find any services for you right now. If you have an account, please double-check your login information. If you\'re still having trouble, reach out to <a href="mailto:hello@sellit.com">hello@sellit.com</a>. If you don\'t have an account yet, why not sign up for <a href="https://sellitlikeserhant.com/membership-b/">membership today</a>?',
        );
        setLoading(false);
      }
    };
    auth0Client.checkSession({}, (err, authResult) => {
      if (!authResult) {
        move('/login');
        return;
      }
      if (err) {
        // Handle any errors
        console.error(err);
      } else {
        setMessage('Authentication successful! :-) Please wait while we redirect you...');
        getSsoUrls(authResult.accessToken);
      }
    });
  }, []);
  return (
    <Card>
      {loading && <Loader />}
      {message ? (
        <div className="switchboard__message" dangerouslySetInnerHTML={{ __html: message }}></div>
      ) : null}
    </Card>
  );
};

export default Switchboard;
