import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../store/GlobalContext';
import Loader from '../../components/loader';
import { useTitle } from '../../hooks/title';
import PageTitle from '../../components/page-title';
import Card from '../../components/card';
import Arrow from '../../components/arrow';

const ResetPassword: React.FC = () => {
  // FE - service to confirm email exists
  // [x] BE - controller to confirm email exists
  // [x] BE - send reset email
  // FE - parse URL, send to BE
  // BE - validate password reset URL
  // FE - send new password to BE
  // BE - update user
  // FE - redirect user
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const emailRef: any = useRef();
  const formRef: any = useRef();
  const { apiService, utilityService } = useContext(GlobalContext);
  useTitle('Reset Password');

  const handleSubmit = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setError('');
    setSuccess('');
    try {
      if (!email) {
        setError('*Email is required.');
        return;
      }
      if (!utilityService.validateEmail(email)) {
        setError(
          "Email format is invalid. Please include an '@' and an extension in the email address.",
        );
        return;
      }
      if (!utilityService.validateEmail(email)) {
        setError('Invalid email.');
        return;
      }
      setLoading(true);
      const response = await apiService.emailPasswordResetLink(email);
      if (response?.success) {
        setSuccess(response.message);
      }
      console.log({ response });
      setLoading(false);
    } catch (e) {
      if ((e as any)?.response) {
        setError((e as any)?.response?.data?.message);
        console.error((e as any)?.response?.data);
      } else {
        console.error(e);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Reset password</PageTitle>
      <Card>
        <form ref={formRef} className="form">
          <label className="form__label" htmlFor="email">
            Email*
          </label>
          <input
            id="email"
            ref={emailRef}
            autoFocus
            name="email"
            className="form__input form__input--text"
            type="text"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          {error ? <div className="form__error">{error}</div> : null}
          {success ? <div className="form__success">{success}</div> : null}
          {loading ? (
            <Loader />
          ) : success ? null : (
            <button
              className="form__input form__input--submit"
              type="submit"
              onClick={handleSubmit}
            >
              Reset Password
              <Arrow />
            </button>
          )}
          <div className="form__link">
            <Link to="/login">Return to Log In</Link>
          </div>
        </form>
      </Card>
    </>
  );
};

export default ResetPassword;
