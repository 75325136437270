import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import ApiService from './services/api';
import GlobalContext from './store/GlobalContext';
import './styles/styles.scss';
import { Auth0Provider } from '@auth0/auth0-react';
import UtilityService from './services/utility';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

document.title = 'SERHANT. Ventures';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F9C313',
    },
  },
  typography: {
    fontFamily: `"Jost", "Helvetica", "Arial", sans-serif`,
    button: {
      textTransform: 'none',
      label: {
        fontSize: 14,
      },
      fontSize: '14px !important',
    },
    h1: {
      fontSize: 28,
      fontWeight: 700,
      color: 'white',
      textAlign: 'center',
    },
    h5: {
      fontSize: 28,
      fontWeight: 300,
      color: 'white',
      textAlign: 'center',
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      textAlign: 'center',
    },
  },
});

const initialize = async () => {
  const apiService = new ApiService();
  const response = await apiService.getAuth0Config();
  const { domain, clientId } = response;
  const initialContext = {
    apiService,
    utilityService: new UtilityService(),
    auth0: {
      domain,
      clientId,
      configurationBaseUrl: `https://${domain}`,
      scope: 'openid read:current_user profile email',
      redirectUrl: `${window.location.protocol}//${window.location.host}`,
    },
    queryParams: new URLSearchParams(window.location.search),
  };
  ReactDOM.render(
    <GlobalContext.Provider value={initialContext}>
      <App />
    </GlobalContext.Provider>,
    document.getElementById('root'),
  );
};

initialize();
