import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../components/loader';
import GlobalContext from '../../store/GlobalContext';
import { useMove } from '../../hooks/move';
import useAuth0 from '../../hooks/auth0';
import Card from '../../components/card';
import PasswordToggle from '../../components/password-toggle';
import Arrow from '../../components/arrow';

const ResetPasswordLink: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordInputType, setPasswordInputType] = useState('password');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [resetPasswordPayload, setResetPasswordPayload] = useState({});
  const { apiService, utilityService } = useContext(GlobalContext);
  const { auth0Client } = useAuth0();
  const move = useMove();
  const handleSubmit = async (event: any) => {
    setError('');
    event.stopPropagation();
    event.preventDefault();
    if (!password || password.length < 8) {
      setError('Password must be at least 8 characters.');
      return;
    }
    setLoading(true);

    try {
      await apiService.submitPasswordReset({ ...resetPasswordPayload, password });
      setSuccess('Password updated! We are logging you in now...');
      await utilityService.sleep(1500);
      const search = new URLSearchParams(window.location.search);
      search.append('email', email);
      auth0Client.login(
        {
          username: email,
          password,
          realm: 'Username-Password-Authentication',
        },
        async (response) => {
          console.log({ response });
          if (response?.description && response?.description.includes('Wrong email')) {
            setError('Invalid email or password.');
            setLoading(false);
          }
        },
      );
    } catch (e) {
      setError(
        `Could not reset password. Please try again later. (Error: ${
          (e as any)?.response?.data?.message
        }}`,
      );
      setLoading(false);
    }
    return;
  };

  useEffect(() => {
    const extractPathParts = () => {
      const pathParts = window.location.pathname.split('/').filter(Boolean);
      if (pathParts.length < 4) {
        return null;
      }
      const string = Buffer.from(pathParts[1], 'base64').toString('utf8');
      let extractedEmail;
      let data;
      try {
        data = JSON.parse(string);
        extractedEmail = data.email;
      } catch (e) {
        extractedEmail = string;
        data = {};
      }
      return {
        email: extractedEmail,
        timestamp: parseInt(pathParts[2]),
        hash: pathParts[3],
      };
    };
    const submitPasswordResetPayload = async (parts: any) => {
      if (!parts) {
        return;
      }
      try {
        const response = await apiService.validatePasswordResetPayload(parts);
        if (response.success) {
          setSuccess('Your password reset link is valid. Please update your password.');
          setLoading(false);
        } else {
          throw new Error();
        }
      } catch (e) {
        setError('Password reset link is invalid or expired. Please create a new link.');
        setTimeout(() => move('/reset-password'), 2000);
      }
    };
    const parts = extractPathParts();
    if (parts) {
      setResetPasswordPayload(parts);
      submitPasswordResetPayload(parts);
      setEmail(parts.email);
    } else {
      setError('Your password reset link is invalid. Please create a new one.');
      return;
    }
    console.log({ parts });
  }, []);

  const togglePasswordInputType = () => {
    setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
  };

  return (
    <Card>
      <form className="form">
        {success && (
          <>
            <div className="form__success">{success}</div>
            <br />
            <br />
            <label className="form__label" htmlFor="email">
              Email
            </label>
            <input
              autoFocus
              name="email"
              className="form__input form__input--text"
              type="text"
              value={email}
              disabled
            />
            <label className="form__label" htmlFor="password">
              Password*
            </label>
            <div className="password-input">
              <input
                id="password"
                autoFocus
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                name="password"
                className="form__input form__input--text"
                type={passwordInputType}
              />
              <span
                className="password-input__toggle"
                role="button"
                onClick={togglePasswordInputType}
              >
                <PasswordToggle passwordInputType={passwordInputType} />
              </span>
            </div>
          </>
        )}
        {error ? <div className="form__error">{error}</div> : null}{' '}
        {loading ? (
          <Loader />
        ) : (
          <button
            className="form__input form__input--submit"
            type="submit"
            value="Update Password"
            onClick={handleSubmit}
          >
            Update Password
            <Arrow />
          </button>
        )}
      </form>
    </Card>
  );
};

export default ResetPasswordLink;
