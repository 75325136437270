import { createContext } from 'react';
import ApiService from '../services/api';
import UtilityService from '../services/utility';

type AppStore = {
  apiService: ApiService;
  utilityService: UtilityService;
  auth0: {
    clientId: string;
    domain: string;
    scope: string;
    redirectUrl: string;
    configurationBaseUrl: string;
  };
  queryParams?: URLSearchParams;
};

const GlobalContext = createContext<AppStore>({} as AppStore);
export default GlobalContext;
